import React from 'react';

import Layout from '../components/layout';
import Seo from '../components/seo';

export default () => (
  <Layout>
    <Seo title="Objednávka webových služeb je odeslána | HTMLFACTORY" />

    <div className="text-center py-5 bg-light">
      <div className="container">
        <h1 className="display-3 text-success font-weight-bold">
          A je hotovo!
        </h1>
        <p>
          Vaši objednávku jsme zaevidovali v našem systému.{" "}
          <br className="d-none d-sm-inline" />
          Nejpozději do 48 hodin vás budeme kontaktovat.
        </p>
        <a href="https://www.html-factory.cz/" className="btn btn-secondary">
          Zpět na homepage
        </a>
      </div>
    </div>

    <div className="text-center py-5">
      <div className="container">
        <h2 className="mb-4 text-center">Co bude následovat?</h2>

        <ul className="list-unstyled row mt-n4 text-left">
          <li className="col-lg-4 pt-4 d-flex">
            <div className="bg-light p-4 flex-grow-1 d-flex d-lg-block">
              <strong className="d-block h3 text-center mb-0 mr-3 mr-lg-0 mb-lg-3">
                1.
              </strong>
              <p className="mb-0 align-self-center">
                Ověříme správnost objednávky, doplníme chybějící nebo nejasné
                informace.
              </p>
            </div>
          </li>
          <li className="col-lg-4 pt-4 d-flex">
            <div className="bg-light p-4 flex-grow-1 d-flex d-lg-block">
              <strong className="d-block h3 text-center mb-0 mr-3 mr-lg-0 mb-lg-3">
                2.
              </strong>
              <p className="mb-0 align-self-center">
                Vystavíme zálohovou fakturu, která je obvykle 40% z celkové
                objednávky a po jeji uhrazení se pouštíme do práce.
              </p>
            </div>
          </li>
          <li className="col-lg-4 pt-4 d-flex">
            <div className="bg-light p-4 flex-grow-1 d-flex d-lg-block">
              <strong className="d-block h3 text-center mb-0 mr-3 mr-lg-0 mb-lg-3">
                3.
              </strong>
              <p className="mb-0 align-self-center">
                V průběhu realizace budete průběžně informován o stavu projektu.
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </Layout>
)
